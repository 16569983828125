<template>
  <div class="page-content">
    <div class="mx-1">
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <h3>Companies</h3>
            <div class="row d-flex justify-content-between align-items-center pt-3">
              <div class="col-12 col-sm-12">
                <form class="search-form" @submit.prevent="page = 1; searchCompany()">
                  <div class="input-group border rounded-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-0 rounded-sm">
                        <FeatherIcon type="search" class="icon-md cursor-pointer" />
                      </div>
                    </div>
                    <input v-model="companyName" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Brand Name</th>
                    <th>Address</th>
                    <th>Created At</th>
                    <th colspan="4">Stats</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(company, i) in companies" :key="i" @click="setWorkspace(company)">
                    <td>{{ company.name }}</td>
                    <td>
                      <span v-if="company.brand_name == null">Not Set</span>
                      <span v-else><b>{{ company.brand_name }}</b></span>
                    </td>
                    <td>{{ company.address }}</td>
                    <td>{{ dateConverter(company.created_at)}}</td>
                    <td class="text-capitalize">
                      <Status :online="company.status == 'active'" />
                      <span class="ml-2">{{ company.status }}</span>
                      <p class="text-muted" style="font-size: .75em">Synced a few second ago</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>

      <el-pagination
      :page-size="10"
      layout="prev, pager, next"
      :total="dataTotal"
      :current-page="parseInt(page)"
      @current-change="changePage"
      >

      </el-pagination>
    </div>
  </div>
</template>
<style>
#btn_link {
  background:none;border: none;color: #1e5b2a;
}
#btn_link:hover {
  text-decoration: underline;
  color: #143d1c;
}
.btn-search {
  margin-left: 1em;
}
.pageBox {
  background-color: #1e5b2a;
  color: white;
  padding: 0.5em 1em;
  margin: 0.5em 1em;
  cursor: pointer;
  border: 0;
}
.pageBox:hover {
  color: white;
}
</style>
<script>
import { isMatch } from 'lodash';
import moment from 'moment';

export default {
  beforeCreate() {
    this.moment = moment;
  },
  name: 'Company',
  metaInfo: {
    title: 'Company',
  },
  data() {
    return {
      companyName: this.$route.query.companyName || '',
      dataSize: 0,
      dataTotal: 0,
      searchName: this.$route.query.companyName || '',
      page: this.$route.query.pageNumber || 1,
    };
  },
  computed: {
    companies() {
      return this.$store.state.backOffice.companies;
    },
  },
  async mounted() {
    // this.$store.dispatch('company/clearActiveCompany');
    this.searchCompany();
    this.dataTotal = this.$store.state.backOffice.companiesSize;
    this.dataSize = Math.ceil(this.$store.state.backOffice.companiesSize / 10);
  },
  watch: {
  },
  components: {
    Status: () => import('../components/Status.vue'),
  },
  methods: {
    dateConverter(date) {
      const createdAt = new Date(date);
      return `${createdAt.toLocaleDateString('id-ID')} ${createdAt.toLocaleTimeString('id-ID')}`;
    },
    setWorkspace(company) {
      this.$store.dispatch('company/setActiveCompany', company)
        .then(() => {
          this.$router.push({ name: 'Workspaces', query: { cid: company.id } });
        });
    },
    async searchCompany() {
      this.searchName = encodeURIComponent(this.companyName);
      const options = { pageNumber: this.page, companyName: this.searchName };

      if (!isMatch(this.$route.query, options)) {
        this.$router.replace({ query: options });
      }
      console.log('Reload...');
      const loader = this.$loading.show();
      await this.$store.dispatch('backOffice/loadCompanies', options);
      this.dataTotal = this.$store.state.backOffice.companiesSize;
      this.dataSize = Math.ceil(this.$store.state.backOffice.companiesSize / 10);
      loader.hide();
    },
    async changePage(currentPage) {
      this.page = currentPage;
      this.searchCompany();
    },
  },
};
</script>
